export const environment = {
    production: false,
    qualification: false,
    integration: false,
    settings: {
        api: {
            enableApiEntreprise: false,
            host: 'http://localhost:8081'
            //https://api.mon-entreprise.laregion.fr
        },
        auth: {
            keycloak: {
                url: 'https://auth-dev.mon-entreprise.laregion-inetum.net/auth',
                realm: 'gdi-entreprise-recette-azure-region',
                clientId: 'gdi-entreprise'
            }
        },
        hub: {
            url: 'https://hubentreprendre.laregion-inetum.net'
        },
        netheos: {
            enable: false,
            url: 'https://api.ekeynox.net/'
        },
        recaptcha: {
            siteKey: '',
            secret: ''
        },
        service: {
            default: ''
        },
        support: '',
        matomo: {
            id: '',
            host: ''
        }
    }
};
